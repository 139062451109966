.logo{
    margin:0
}
header{
    display: flex;
    color: white !important;
    align-items: center;
    justify-content: space-between;
}
a{
    color: white !important;
}
#signed-user{
    display: flex;
    align-items: center;
}
#signed-user p{
    margin: 0;
    margin-right: 10px;
}
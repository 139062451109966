#side-nav-buttons button {
  width: 100%;
}
.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

#myuploads-videos {
  display: flex;
}
.my-upload-header{
  display: flex !important;
  flex-direction: column;
  align-items: end;
  background: white;
  height: 44px;
}
.my-upload-header button{
  margin:10px 5px;
}